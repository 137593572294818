export const SkillItems = [
  {
    name: 'Languages',
    items: ['Java', 'C++', 'C', 'Python', 'HTML/CSS', 'Javascript', 'SQL'],
    cName: 'skill-container',
  },
  {
    name: 'Frameworks',
    items: ['React', 'Node', 'SpringBoot', 'Postman', 'Vaadin'],
    cName: 'skill-container',
  },
  {
    name: 'Tools/Other',
    items: ['Git', 'Linux', 'MongoDB', 'MySQL', 'Figma'],
    cName: 'skill-container',
  },
]
