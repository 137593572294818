export const WorksItems = [
  {
    name: 'JPEG Compressor',
    utilized: ['C'],
    description:
      'A lossy jpeg compressor and decompressor. Utilizes color space conversion, quantization, bit packing, etc.',
    links: [
      //   {
      //     icon: 'fa-solid fa-globe',
      //     url: 'https://spotify-profile-visualizer-app.herokuapp.com/',
      //   },
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Image-Compressor',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Image-Compressor',
  },
  {
    name: 'Grep Command',
    utilized: ['C++'],
    description:
      'The grep command which searches for text in a tree of directories. Implemented a quadratic probing hash table to store and retrieve text from.',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Grep-Command',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Grep-Command',
  },
  {
    name: 'Travel Journal',
    utilized: ['React', 'HTML', 'CSS'],
    description:
      'A web application that acts as a journal for travel information.',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Travel-Journal',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Travel-Journal',
  },
  {
    name: 'Universal Machine',
    utilized: ['C'],
    description:
      'A Universal Machine that handles 14 RISC-style instructions. Emulates the state, logic, and hardware of a computer to run a given program.',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Universal-Machine',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Universal-Machine',
  },
  {
    name: 'RPN Calculator',
    utilized: ['Universal Machine Macro Assembler'],
    description:
      'A Reverse Polish Notation Calculator in Universal Machine Macro Assembler Language. Low-level programming, implementing call stack and following calling conventions.',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Grep-Command',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Grep-Command',
  },
  {
    name: 'JUnit',
    utilized: ['Java'],
    description:
      'A JUnit-like testing framework that offers the automated program testing technique QuickCheck.',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/JUnit',
      },
    ],
    hyperlink: 'https://github.com/bergj21/JUnit',
  },
  {
    name: 'MBTA Simulator',
    utilized: ['Java'],
    description:
      'A multi-threaded simulation of the MBTA. Passengers ride trains between stations, boarding and deboarding to complete their journey',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Concurrent-Train-Simulator',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Concurrent-Train-Simulator',
  },
  {
    name: 'Java on Rails',
    utilized: ['Java'],
    description:
      'A Ruby on Rails inspired web framework that utilizes the model-view-controller architecture.',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Java-on-Rails',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Java-on-Rails',
  },
  {
    name: 'Space Invaders',
    utilized: ['VHSIC Hardware Description Language'],
    description:
      'A version of the arcade game Space Invaders implemented in a hardware description language',
    links: [
      {
        icon: 'fa-brands fa-github',
        url: 'https://github.com/bergj21/Space-Invaders',
      },
    ],
    hyperlink: 'https://github.com/bergj21/Space-Invaders',
  },
]
