import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className="footer">
      <a
        href={'https://github.com/bergj21'}
        target="_blank"
        rel="noopener noreferrer"
      >
        John Berg
      </a>
    </div>
  )
}

export default Footer
