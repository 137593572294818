export const ExperienceItems = [
  {
    company: 'P/E Investments',
    role: 'Software Engineering Intern',
    start: 'June 2024',
    end: 'August 2024',
    detail: [
      'Utilized Java, SpringBoot, Vaadin, and MySQL to develop a conference management and analysis web application for integration into company’s CRM system ',
      'Implemented features to search and maintain conference-related data, supported tracking of conference expenditures through vendors, provided analysis of company growth through conference attendance',
      'Presented project to 50 colleagues, sharing the current features and next steps of the web application',
    ],
  },
  {
    company: 'Good Energy',
    role: 'Energy Consulting Intern',
    start: 'May 2023',
    end: 'August 2022',
    detail: [
      'Supported existing and developing programs that includes energy procurement for 200 municipal customers by interfacing with program members to resolve account and billing issues',
      'Led a solar program marketing campaign by developing a landing page in Word Press and reaching out to 100 communities, acquiring over a dozen new participating communities',
      'Managed a database of 10,000 customers, entering account information and parsing executed contracts',
    ],
  },
  {
    company: 'Code Ninjas',
    role: 'Camp Director',
    start: 'June 2022',
    end: 'August 2022',
    detail: [
      'Taught 6 different course curriculums to 150 campers ranging from 6 - 14 years of age',
      'Utilized Python, Lua, and Scratch to manipulate video games (Roblox, Minecraft, etc.), creating an educational and fun programming experience that was adapted toward the age of the students',
      'Coordinated with 5 counselors to meet the goals of the camp and ensure proper facilitation of curriculum',
    ],
  },
]
